body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, brandon-grotesque, sans-serif,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image_frame {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  top: 0;
  left: 0;
}
.buttoncurved {
  border-radius: 18px !important;
}

.buttoncircular {
  height: 100px;
  width: 100px;
  margin: 10%;
  border-radius: 50% !important;
  margin-left: auto;
  margin-right: auto;
}

.custominput {
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  padding: 0.67857143em 1em;
  line-height: 1.21428571em;
}

.haflcircle {
  width: 180px;
  height: 110px;
  margin-left: 172px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.actionlink {
  color: #d3d3d3;
}

.forgotpass {
  color: grey;
  text-align: center;
  cursor: pointer;
}
.forgotpass:hover {
  color: blue;
}

.incitelogo {
  width: 800px;
  height: 500px;
  display: block;
  margin: auto;
}

.select {
  cursor: pointer;
  padding: 15px;
}

.textformat {
  white-space: pre-wrap;
}

@media screen and (max-wath: 700px) {
  .incitelogo {
    width: 400px;
    height: 250px;
    display: block;
    margin: auto;
  }
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .incitelogo {
    width: 600px;
    height: 375px;
    display: block;
    margin: auto;
  }
}
